import React, { useState, useEffect } from "react";
import I18n from "../lang";

export const CookiePolicyModal = () => {
    const [customize, setCustomize] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const cookiePolicyStorage = localStorage.getItem('cookiePolicyStorage');

    useEffect(()=>{
        if(!cookiePolicyStorage){
            setShowModal(true);
        }
    }, [])

    return (
        <>
            { showModal ? <div className="cookie-policy-modal">
                {   !customize &&
                    <div className="customize-content" style={{ margin: 0 }}>
                        <h2>{ I18n('cookies_title') }</h2>
                        <div className="customize-item" dangerouslySetInnerHTML={{__html: I18n('cookies_content')}}></div>
                    </div>
                }
                { customize ? <div className="customize-content">
                    <h2>{ I18n('cookies_customize_title') }:</h2>
                    {
                        I18n('cookies_customize_entries').map((item, index)=> {
                            return <div className="customize-item" key={index}>
                                <h3>{ item.title } <label className="checkbox-item"><input type="checkbox" name="" id="" /><div className="checkbox-item-box"></div></label></h3>
                                <p>{ item.content }</p>
                            </div>
                        })
                    }
                </div> : null }
                <div className="btns-wrpr">
                    <a href="/#" className="btn btn-light-blue cookie-policy-customize-btn" onClick={(e)=>{
                        e.preventDefault();
                        setCustomize(!customize);
                    }}><span>{customize ? I18n('cookies_custommize_back_button') : I18n('cookies_customize_button')}</span></a>
                    { !customize ? <>
                        <a href="/#" className="btn btn-brown" onClick={(e)=>{
                            e.preventDefault();
                            localStorage.setItem('cookiePolicyStorage', 'reject');
                            setShowModal(false); 
                        }}><span>{ I18n('cookies_reject_buttom') }</span></a>
                        <a href="/#" className="btn btn-green" onClick={(e)=>{
                            e.preventDefault();
                            localStorage.setItem('cookiePolicyStorage', 'accept');
                            setShowModal(false); 
                        }}><span>{ I18n('cookies_accept_button') }</span></a>
                    </> : <a href="/#" className="btn btn-green" onClick={(e)=>{
                            e.preventDefault();
                            localStorage.setItem('cookiePolicyStorage', 'custom');
                            setShowModal(false); 
                        }}><span>{ I18n('cookies_customize_accept_button') }</span></a>
                    }

                </div>
            </div> : null }
        </>
    )
}