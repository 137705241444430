const PageLoader = () => {
    return (
        <>
            <div className="preloader">
                <svg id="nut-logo" xmlns="http://www.w3.org/2000/svg" width="32.413" height="31.78" viewBox="0 0 32.413 31.78">
                    <path d="M25.644,32.613a19.284,19.284,0,0,0-2.04,2.145,7.577,7.577,0,0,1-1.964,1.558,5.644,5.644,0,0,1-4.481.384c-1.519-.444-2.918-1.141-4.4-1.657a7.025,7.025,0,0,0-5.008,0,7.568,7.568,0,0,0-1.119.554,9.18,9.18,0,0,0-.938.642,3.622,3.622,0,0,0-.378.318,12.878,12.878,0,0,0,4.909,4.531c1.7.938,3.423,1.838,5.145,2.743,1.042.543,2.084,1.092,3.116,1.651a.83.83,0,0,0,.971-.044c.905-.565,1.843-1.075,2.8-1.563,1.19-.609,2.408-1.19,3.6-1.788,4.8-2.413,8.063-5.743,8.244-10.619a7.948,7.948,0,0,0-2.924-.806,7.818,7.818,0,0,0-5.524,1.958Z" transform="translate(-2.397 -13.837)" fill="#ccc"/>
                    <path d="M31.639,21.875a25.466,25.466,0,0,0-3.379-1.613,14.256,14.256,0,0,0-9.851-.285,26.387,26.387,0,0,0-4.3,1.991,28.685,28.685,0,0,1-5.578,2.715,7.35,7.35,0,0,1-4.569-.044A11.152,11.152,0,0,1,.768,22.862C.57,22.709,0,22.286,0,22.286A15.258,15.258,0,0,0,2.282,30.07h0a7.954,7.954,0,0,1,7.756-1.859c1.508.516,2.935,1.212,4.476,1.657a5.778,5.778,0,0,0,4.547-.384,7.62,7.62,0,0,0,1.991-1.558,19.615,19.615,0,0,1,2.073-2.145,8.025,8.025,0,0,1,5.611-1.958,8.858,8.858,0,0,1,3.439,1.031,8.42,8.42,0,0,0,.219-1.81A1.148,1.148,0,0,0,32,22.127a2.335,2.335,0,0,0-.373-.252Z" transform="translate(0 -8.683)" fill="#93bebb"/>
                    <path d="M7.663,2.525A11.311,11.311,0,0,0,4.12,5.038c3.516,2.83,8.749,4.032,13.993,3.7A26.179,26.179,0,0,0,25.43,7.226,19.991,19.991,0,0,0,31.381,3.88c.088-.071.176-.137.258-.208C27.158.825,22.9.26,18.2.26A25.038,25.038,0,0,0,7.663,2.525Z" transform="translate(-1.86 -0.117)" fill="#164361"/>
                    <path d="M1.493,11.51h0a2.994,2.994,0,0,0-.565.878A14.027,14.027,0,0,0,.1,17.061h0a13.062,13.062,0,0,0,4.739,2.43,9.1,9.1,0,0,0,6.078-1.113c1.514-.779,2.984-1.635,4.487-2.43.3-.159.6-.313.911-.466-5.562.351-11.124-.938-14.821-3.971Z" transform="translate(-0.045 -5.197)" fill="#8fb6d4"/>
                    <path d="M51.963,13.908a13.311,13.311,0,0,0-.719-2.765c-.038-.11-.082-.219-.126-.324a16.835,16.835,0,0,0-.79-1.689h0a23.672,23.672,0,0,1-6.648,3.692,17.844,17.844,0,0,1,8.442,3.165,9.738,9.738,0,0,0-.165-2.073Z" transform="translate(-19.721 -4.122)" fill="#8fb6d4"/>
                    <path d="M31.77,0Z" transform="translate(-13.344)" fill="#164361"/>
                </svg>
            </div>
        </>
    )
}

export default PageLoader;