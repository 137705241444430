import React, { useState, useEffect } from 'react';
import { ReactComponent as Logo } from "../assets/img/logo.svg";
import { Link, NavLink, useNavigate, useParams, useLocation } from 'react-router-dom';
import SelectBox from "./SelectBox";

import I18n from "../lang";

const Header = () => {
    const navigate = useNavigate();
    const [mobileNavOpen, setMobileNavOpen] = useState(false);
    const [language, setLanguage] = useState(null);

    const { lang } = useParams();
    const loc = useLocation();
    
    const languageChange = (value) => {
        let currentRoute = loc.pathname.split('/').pop();
        currentRoute = currentRoute.length > 3 ? currentRoute : '';
        //console.log(currentRoute);
        setLanguage(value);
        navigate(value === 'en' ? `/${currentRoute}` :`/${value}/${currentRoute}`);
        localStorage.setItem('lang', value);
    }

    useEffect(()=>{
        if(lang && language !== lang){
            setLanguage(lang);
        } else {
            setLanguage('en');
        }
    },[lang])

    useEffect(()=>{
        let localStorageGettedLang = localStorage.getItem('lang');
        if(localStorageGettedLang && localStorageGettedLang !== 'en'){
            setLanguage(localStorageGettedLang);
            // navigate(localStorageGettedLang === 'en' ? '/' :`/${localStorageGettedLang}`);
        }
    },[])

    return (
        <>
            <header className="header">
                <div className="header-containter">
                    <Link to={`${lang ? '/'+ lang : '/'}`} className="header_logo" onClick={()=>{ setMobileNavOpen(false); }}>
                        <Logo />
                    </Link>
                    <div className="iso-badge">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14.59" height="10.607" viewBox="0 0 14.59 10.607">
                            <g transform="translate(-563.469 -604.474)">
                                <g transform="translate(564.883 608.817) rotate(45)" fill="none" stroke="#164361" strokeWidth="1.5">
                                    <rect width="6.858" height="2" rx="1" stroke="none"/>
                                    <rect x="0.75" y="0.75" width="5.358" height="0.5" rx="0.25" fill="none"/>
                                </g>
                                <g transform="translate(578.059 605.889) rotate(135)" fill="none" stroke="#164361" strokeWidth="1.5">
                                    <rect width="13" height="2" rx="1" stroke="none"/>
                                    <rect x="0.75" y="0.75" width="11.5" height="0.5" rx="0.25" fill="none"/>
                                </g>
                            </g>
                        </svg>

                        ISO 22000:2018 Certified Processing Facility
                    </div>
                    <nav className={`header_nav ${mobileNavOpen ? 'active' : ''}`}>
                        <ul>
                            <li><NavLink to={`${lang ? '/'+ lang : ''}/products`} onClick={()=>{ setMobileNavOpen(false); }}>{ I18n('nav_products') }</NavLink></li>
                            <li><NavLink to={`${lang ? '/'+ lang : ''}/about-us`} onClick={()=>{ setMobileNavOpen(false); }}>{ I18n('nav_about') }</NavLink></li>
                            <li><NavLink to={`${lang ? '/'+ lang : ''}/request-samples`} onClick={()=>{ setMobileNavOpen(false); }}>{ I18n('nav_request') }</NavLink></li>
                            <li>
                                <div className="flex items-center">
                                    { language ?
                                    <div className="select-languages mr-[10px]">
                                        <SelectBox value={language} change={languageChange} />
                                    </div> : null }
                                    <NavLink to={`${lang ? '/'+ lang : ''}/contact-us`} className="btn btn-sm btn-blue" onClick={()=>{ setMobileNavOpen(false); }}>
                                        <span>{ I18n('nav_button') }</span>
                                    </NavLink>
                                </div>
                            </li>
                        </ul>
                    </nav>
                    <a href="/#" className={`mobile-nav-btn ${mobileNavOpen ? 'active' : ''}`} onClick={(e)=>{
                        e.preventDefault();
                        setMobileNavOpen(!mobileNavOpen);
                    }}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </a>
                </div>
            </header>
        </>
    )
}

export default Header;
