import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet } from "react-router-dom";
import { useLocation, useParams } from "react-router";
import { CookiePolicyModal } from "../components/CookiePolicyModal"

export const Layout = () => {
    const location = useLocation();
    const [show, setShow] = useState(false);
    const isSnap = navigator.userAgent === 'ReactSnap'
    const { lang } = useParams();

    const getLanguages = async() => {
        setShow(false);
        if (document.querySelector('.preloader').classList.contains('hide')){
            document.querySelector('.preloader').classList.remove('hide');
        }
        const langData =  await fetch(`${window.location.origin}/lang/${lang || 'en'}.json`)
        .then((response) => response.json())
        .then((json) => json);
        window.langs = langData;
        setShow(true);
        setTimeout(() => {
            document.querySelector('.preloader').classList.add('hide');
        }, 1500);
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    useEffect(()=>{
        getLanguages();
    },[lang])

    return (
        show ? <>
            <Header />
                <main className={`main-container ${lang && lang !== 'en' ? 'main-not-en' : ''}`}>
                    <Outlet />
                </main>
            <Footer />
            { isSnap ? null : <CookiePolicyModal /> }
        </> : null
    )
}