import React , { lazy, Suspense  } from "react";
import {createBrowserRouter, createRoutesFromElements, Route, } from "react-router-dom";

//import { Pages } from "./index.js";

//const Layout = lazy(() => import("../layout/Layout.jsx"));
import PageLoader from "../components/PageLoader.jsx";
import { Layout } from "../layout/Layout.jsx"

const Home = lazy(()=> delayForDemo(import("./Home/Home.jsx")));
const AboutUs = lazy(()=> delayForDemo(import("./AboutUs/AboutUs.jsx")));
const Products = lazy(() => delayForDemo(import("./Products/Products.jsx")));
const ContactUs = lazy(() => delayForDemo(import("./ContactUs/ContactUs.jsx")));
const RequestSamples = lazy(() => delayForDemo(import("./RequestSamples/RequestSamples.jsx")));
const Privacy = lazy(() => delayForDemo(import("./Privacy/Privacy.jsx")));
const CookiePolicy = lazy(() => delayForDemo(import("./CookiePolicy/CookiePolicy.jsx")));

function delayForDemo(promise) {
    return new Promise(resolve => {
        setTimeout(resolve, 1500);
    }).then(() => promise);
}

export const router = createBrowserRouter(
    createRoutesFromElements(
        <>
            <Route element={<Layout />}>
                <Route path="/">
                    <Route path="/" element={
                        <Suspense fallback={<PageLoader/>}>
                            <Home />
                        </Suspense>
                    }/>

                    <Route path="/about-us" element={
                        <Suspense fallback={<PageLoader/>}>
                            <AboutUs/>
                        </Suspense>
                    }/>
                    <Route path="/products" element={
                        <Suspense fallback={<PageLoader/>}>
                            <Products/>
                        </Suspense>
                    }/>
                    <Route path="/contact-us" element={
                        <Suspense fallback={<PageLoader/>}>
                            <ContactUs/>
                        </Suspense>
                    }/>
                    <Route path="/request-samples" element={
                        <Suspense fallback={<PageLoader/>}>
                            <RequestSamples/>
                        </Suspense>
                    }/>
                    <Route path="/privacy-policy" element={
                        <Suspense fallback={<PageLoader/>}>
                            <Privacy/>
                        </Suspense>
                    }/>
                    <Route path="/cookie-policy" element={
                        <Suspense fallback={<PageLoader/>}>
                            <CookiePolicy/>
                        </Suspense> 
                    }/>
                </Route>
                <Route path="/:lang">
                    <Route path="/:lang/" element={
                        <Suspense fallback={<PageLoader/>}>
                            <Home />
                        </Suspense>
                    }/>

                    <Route path="/:lang/about-us" element={
                        <Suspense fallback={<PageLoader/>}>
                            <AboutUs/>
                        </Suspense>
                    }/>
                    <Route path="/:lang/products" element={
                        <Suspense fallback={<PageLoader/>}>
                            <Products/>
                        </Suspense>
                    }/>
                    <Route path="/:lang/contact-us" element={
                        <Suspense fallback={<PageLoader/>}>
                            <ContactUs/>
                        </Suspense>
                    }/>
                    <Route path="/:lang/request-samples" element={
                        <Suspense fallback={<PageLoader/>}>
                            <RequestSamples/>
                        </Suspense>
                    }/>
                    <Route path="/:lang/privacy-policy" element={
                        <Suspense fallback={<PageLoader/>}>
                            <Privacy/>
                        </Suspense>
                    }/>
                    <Route path="/:lang/cookie-policy" element={
                        <Suspense fallback={<PageLoader/>}>
                            <CookiePolicy/>
                        </Suspense> 
                    }/>
                </Route>
            </Route>
        </>
    ), { basename: "/" }
);